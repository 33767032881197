<template>
  <div>
    <v-dialog v-model="showOptionDialog" max-width="520">
      <v-card :color="colorTheme.card" dark>
        <v-card-text class="text-center">
          User {{ $cookies.get("username") }} is already signed in
        </v-card-text>
        <v-btn
          :color="colorTheme.button"
          dark
          class="regular-letter-size"
          max-width="260"
          @click="continueWithOldUser"
        >
          Continue with user {{ $cookies.get("username") }}
        </v-btn>
        <v-btn
          :color="colorTheme.button"
          dark
          class="regular-letter-size"
          max-width="260"
          @click="continueWithNewUser"
        >
          Continue with user {{ form.username }}
        </v-btn>
      </v-card>
    </v-dialog>
    <v-container>
      <v-row align="center" justify="space-around">
        <v-card v-if="!completeNewPassword" class="pa-8" min-width="460">
          <v-card-title class="pa-5 pb-8">
            <div class="text-title">Sign in to your account</div>
          </v-card-title>
          <v-card-text>
            <v-form v-model="form.valid" ref="form" lazy-validation>
              <v-text-field
                label="Username"
                placeholder="Username"
                v-model="form.username"
                @keyup.enter="signIn"
                tabindex="1"
                required
                :rules="form.rules.username"
                outlined
                :color="colorTheme.textField"
              ></v-text-field>
              <v-text-field
                label="Password"
                placeholder="Password"
                v-model="form.password"
                @keyup.enter="signIn"
                tabindex="2"
                @click:append="form.showPassword = !form.showPassword"
                :append-icon="form.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="form.showPassword ? 'text' : 'password'"
                required
                :rules="form.rules.password"
                outlined
                :color="colorTheme.textField"
              ></v-text-field>
              <div class="pt-0">
                Forgot your password?
                <router-link
                  :to="{
                    name: 'ForgotPassword',
                    query: { ...forgotPasswordQuery },
                  }"
                  tabindex="4"
                >
                  <span class="teal--text">Reset password</span>
                </router-link>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              :disabled="!form.valid"
              @click="signIn"
              tabindex="3"
              large
              min-width="100"
              :color="colorTheme.button"
              class="py-4 px-10 white--text"
            >
              Sign in
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else class="pa-8" min-width="460">
          <v-card-title>Set new password</v-card-title>
          <v-card-text>
            <v-form
              ref="formCompleteNewPassword"
              v-model="formCompleteNewPassword.valid"
              lazy-validation
            >
              <v-text-field
                label="New Password"
                v-model="formCompleteNewPassword.newPassword"
                @keyup.enter="newPasswordConfirmation"
                tabindex="1"
                :append-icon="
                  formCompleteNewPassword.showNewPassword
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                :type="
                  formCompleteNewPassword.showNewPassword ? 'text' : 'password'
                "
                @click:append="
                  formCompleteNewPassword.showNewPassword = !formCompleteNewPassword.showNewPassword
                "
                required
                :rules="formCompleteNewPassword.rules.newPassword"
                outlined
                :color="colorTheme.textField"
              ></v-text-field>
              <v-text-field
                label="Confirm Password"
                v-model="formCompleteNewPassword.confirmNewPassword"
                @keyup.enter="newPasswordConfirmation"
                tabindex="2"
                :append-icon="
                  formCompleteNewPassword.showConfirmNewPassword
                    ? 'mdi-eye'
                    : 'mdi-eye-off'
                "
                :type="
                  formCompleteNewPassword.showConfirmNewPassword
                    ? 'text'
                    : 'password'
                "
                @click:append="
                  formCompleteNewPassword.showConfirmNewPassword = !formCompleteNewPassword.showConfirmNewPassword
                "
                required
                :rules="confirmNewPasswordRules"
                outlined
                :color="colorTheme.textField"
              >
              </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              :disabled="!formCompleteNewPassword.valid"
              @click="newPasswordConfirmation"
              tabindex="3"
              large
              min-width="100"
              :color="colorTheme.button"
              class="py-4 px-10 white--text"
            >
              Confirm
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <custom-error
        ref="showCustomError"
        :ActionError.sync="ActionError"
        :ActionErrorMessage.sync="ActionErrorMessage"
        card-width="460"
        error-title="Login Error"
      ></custom-error>
    </v-container>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { mapActions, mapMutations, mapState } from "vuex";
import { isProduction } from "@/config/organization-config.js";
import { removeUnnecessaryCookies } from "@/utils/column-configurator.js";
import CustomError from "../components/fee-schedule/CustomError.vue";
import BASE from "../store/api-endpoint.js";
import amplitude from 'amplitude-js'

export default {
  props: ["redirectTo"],
  components: {
    CustomError,
  },
  data() {
    return {
      authErrorMessage: "Authorization problem.",
      showOptionDialog: false,
      cognitoUser: {},
      form: {
        valid: true,
        username: "",
        password: "",
        showPassword: false,
        rules: {
          username: [(u) => !!u || "Username is required"],
          password: [(p) => !!p || "Password is required"],
        },
      },
      ActionError: false,
      ActionErrorMessage: "",
      completeNewPassword: false,
      //https://docs.amplify.aws/lib/auth/manageusers/q/platform/js#complete-new-password
      formCompleteNewPassword: {
        valid: true,
        newPassword: "",
        confirmNewPassword: "",
        showNewPassword: false,
        showConfirmNewPassword: false,
        rules: {
          newPassword: [
            (p) => !!p || "Password is required",
            (p) =>
              (p && p.length >= 8) ||
              "Password must be at least 8 characters long",
            (p) => /[a-z]/.test(p) || "Password must contain lowercase letters",
            (p) => /[A-Z]/.test(p) || "Password must contain uppercase letters",
            (p) => /[0-9]/.test(p) || "Password must contain numbers",
            (p) =>
              /[^A-Za-z0-9]/.test(p) ||
              "Password must contain special characters",
          ],
        },
      },
    };
  },
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    forgotPasswordQuery() {
      if (this.form.username) {
        return { username: this.form.username };
      } else {
        return null;
      }
    },
    confirmNewPasswordRules() {
      return [
        (np) => !!np || "This field is required",
        () =>
          this.formCompleteNewPassword.confirmNewPassword ===
            this.formCompleteNewPassword.newPassword || "Passwords do not match"
      ];
    },
  },
  methods: {
    ...mapMutations("dialogs", ["setProductionNotice"]),
    ...mapActions("auth", ["setUser", "signOut", "cancelSignIn", "resetAll"]),
    ...mapActions("endpoints", ["setBaseUrl"]),
    ...mapActions("backgroundMessages", ["startBackgroundActivityTimer"]),
    async signIn() {
      if (this.$refs.form.validate()) {
        if (this.$cookies.isKey("username") == false) {
          this.refreshCustomError(null);
          // User is not signed in => Sign In
          const { username, password } = this.form;
          let user = {};
          try {
            user = await Auth.signIn(username, password).then(user => {
              const organization = BASE.organization;
              const userType = this.getOrganization(user);
              const locationHost = window.location.host;
              if (
                !organization[userType] ||
                !organization[userType].uiUrlHost.includes(locationHost)
              ) {
                this.cancelSignIn();
                throw {
                  code: "NotAuthorizedException",
                  message: "Wrong organization.",
                  name: "NotAuthorizedException"
                };
              }
              amplitude.getInstance().init("4ab8acd24bd2b9e8772ce48b2391715e", user.username);
              amplitude.getInstance().logEvent("User Logged In", {
                "Organization": userType,
              });
              return user;
            });
          } catch (error) {
            if (error.code === "InvalidParameterException") {
              error.message = "Incorrect username or password.";
            }
            console.log("Error signing in", error);
            this.refreshCustomError(error);
            return;
          }
          this.cognitoUser = user;
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.completeNewPassword = true;
            this.refreshCustomError(null);
            return;
          }
          let cookiesAreSet = await this.setUserCookies(user);
          if (!cookiesAreSet) {
            this.refreshCustomError(this.authErrorMessage);
            return;
          }
          let newUser = {
            email: this.$cookies.get("email"),
            username: this.$cookies.get("username"),
            organization: this.$cookies.get("organization")
          };
          await this.setUser(newUser);
          let userType = this.getOrganization(user);
          await this.setBaseUrl(userType);

          this.redirect();
        } else if (this.form.username !== this.$cookies.get("username")) {
          // Someone else is signed in => Show option dialog
          this.showOptionDialog = true;
        } else {
          // User is already signed in => Redirect to home page
          this.redirect();
        }
        this.startBackgroundActivityTimer();
      }
      removeUnnecessaryCookies();
    },
    async newPasswordConfirmation() {
      if (this.$refs.formCompleteNewPassword.validate()) {
        try {
          const { newPassword } = this.formCompleteNewPassword;
          const { requiredAttributes } = this.cognitoUser.challengeParam;

          await Auth.completeNewPassword(
            this.cognitoUser,
            newPassword,
            requiredAttributes
          );

          this.form.password = newPassword;
        } catch (error) {
          console.log(error);
        } finally {
          this.completeNewPassword = false;
        }
      }
    },
    async setUserCookies(user) {
      if (
        !user ||
        !user.username ||
        !user.signInUserSession ||
        !user.signInUserSession.accessToken ||
        !user.signInUserSession.accessToken.jwtToken
      ) {
        return false;
      }
      if (!user.attributes["custom:organization"]) {
        this.authErrorMessage = "User doesn't belong to any organization!";
        return false;
      }
      this.$cookies.set(
        "username",
        user.username,
        BASE.CookieExpiration,
        null,
        null,
        true
      );
      this.$cookies.set(
        "token",
        `Bearer ${user.signInUserSession.accessToken.jwtToken}`,
        BASE.CookieExpiration,
        null,
        null,
        true
      );
      this.$cookies.set(
        "organization",
        user.attributes
          ? user.attributes["custom:organization"]
          : user.challengeParam.userAttributes["custom:organization"],
        BASE.CookieExpiration,
        null,
        null,
        true
      );
      this.$cookies.set(
        "email",
        user.attributes ? user.attributes.email : user.challengeParam["email"],
        BASE.CookieExpiration,
        null,
        null,
        true,
      );
      return true;
    },
    refreshCustomError(error) {
      this.$refs.showCustomError.refresh(error);
    },
    continueWithOldUser() {
      this.showOptionDialog = false;
      this.redirect();
    },
    async continueWithNewUser() {
      this.showOptionDialog = false;
      await this.signOut();
      await this.signIn();
    },
    getOrganization(user) {
      let userType = "";
      if (!user.attributes) {
        userType = user.challengeParam.userAttributes["custom:organization"];
      } else {
        userType = user.attributes["custom:organization"];
      }
      return userType;
    },
    async redirect() {
      console.log("User signed in:", this.$cookies.get("username"));
      console.log("Organization:", this.$cookies.get("organization"));
      if (this.redirectTo) {
        let name = this.redirectTo.name;
        let params = this.redirectTo.params;
        this.$router.replace({
          name: name,
          params: params
        });
      } else {
        this.$router.replace("/");
      }
      if (isProduction(this.$cookies.get("organization"))) {
        this.setProductionNotice(true);
      }
    },
  },
  mounted() {
    if (this.$route.query.username) {
      this.form.username = this.$route.query.username;
    }
    this.resetAll();
  },
};
</script>

<style scoped>
.regular-letter-size {
  text-transform: none;
}
</style>
